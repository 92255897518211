@import "../../../styles/common-imports";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.modalContainer {
    max-width: 1000px;
}

.formContainer {
    padding-top: 62px;
}

.applicationContainer {
    max-height: calc(100vh - 250px);
}

.modalAction {
    padding-right: 28px;
}

.rightPane {
    padding-top: 7px;
}

.commentsContainer {
    --blank-height: 340px; // 250px + 90px approx height of 2 fields above
    border-top-color: $color-silver !important;
    @include customScroll($color-gray-sand, $color-silver, $border-color);
}

.title {
    font-size: 18px;
    font-weight: bold;
}
