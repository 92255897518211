@import '../../styles/variables';

.navItem {
    padding: 6px 12px;
    color: $color-white;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover {
        color: $color-white;
    }
}

.firstLevelNavItem {
    background-color: $color-blue;
}

.navItemFirstLevel {
    border-top: 1px solid $color-blue;
}

.navItemFirstLevelLastElement {
    border-top: 1px solid $color-blue;
    border-bottom: 1px solid $color-blue;
}

.oxfordBlueNavHeaderLink {
    :global {
        a.active {
            background-color: $color-blue-oxford;
            color: $color-white;
        }
    }
}

.navHeaderLink {
    display: flex;

    :global {
        a {
            flex-grow: 1;
            margin-bottom: 0;

            &.active {
                //background-color: $color-blue;
                color: $color-white;

                + div {
                    background-color: lighten($color-blue,5%);
                }
            }
        }
    }
}

.navHeaderLinkFirstLevel {
    :global {
        a.active {
            background-color: $color-blue;
        }
    }
}

.greyNavHeaderLink {
    :global {
        a.active {
            background-color: $color-dark-silver;

            + div {
                background-color: lighten($color-dark-silver,5%);
            }
        }
    }
}

.navHeaderExpanded {
    margin-bottom: 0;
}

.activeNavItem {
    background-color: $color-blue;
    color: $color-white;
}

.activeNavItemNoChildren {
    background-color: $color-dark-silver;
    color: $color-white;
}

.navIcon {
    width: 1rem;
    height: 1rem;
    margin-right: 6px;
}

.navLabel {
    flex-grow: 1;
    max-width: fit-content;
}

.arrowWrapper {
    background-color: rgba($color-white, .1);
    padding: 2px 8px;
    margin-right: 0;
    margin-left: auto;
    color: $color-white;
    display: flex;
    align-items: center;
}

.navItemHeaderChevron {
    transition: transform 0.25s;
}

.chevronExpanded {
    transform: rotate(-180deg);
}

.navChildrenBlock {
    display: flex;
    flex-direction: column;
}

.firstLevelChild {
    border-top: 1px solid $color-blue;
}

.lastElement {
    border-bottom: 1px solid $color-blue;
}
