@import '../../../styles/variables';
@import '../../../styles/common-imports';

.helpPanel {
    width: 35vw;
    max-width: $help-menu-width-sm;
    box-shadow: $sidebar-shadow;
    max-height: 100vh;

    &.close {
        width: 0;
        overflow: hidden;
    }
}

.header {
    padding: 10px;
}

.body {
    padding-top: 16px;

    &.hasSearchBox {
        padding-top: 0;
    }
}

.btn-close {
    font-size: .75rem;
}

.helpSearchContainer {
    height: 30px;
    margin-bottom: 40px;

    &.searchFaqs {
        margin-bottom: 16px;
    }
}

.helpSearch {
    width: 80%;
    min-width: 200px;
    max-width: 500px;
    top: 30px;
    z-index: 10;
}

.helpScreen {
    overflow-y: auto;
}

.nav {
    border-top: 1px solid $color-silver;
}

.navLink {
    border-width: 0 0 1px 0;
    border-bottom: 1px solid $color-silver;
    color: $color-blue-oxford;
    padding-top: 12px;
    padding-bottom: 12px;

    &:hover {
        color: $color-blue;
        border-bottom-color: $color-silver;
    }
}

.introTitle {
    font-size: 22px;
}

.updateSummary {
    p:last-child {
        margin-bottom: 0;
    }
}

.contactTitle {
    font-size: 22px;
}

.faqs {
    button {
        padding-left: 2.875rem;

        &::after {
            position: absolute;
            left: 1.5rem;
            margin-left: 0;
        }
    }
}


@include media-breakpoint-up(xl) {
    .helpPanel {
        width: 40vw;
        max-width: $help-menu-width;
    }
}
