// Brand Colors
$color-blue-oxford: #0c153d;
$color-blue: #1476bd;
$color-blue-light: #6ecde6;
$color-purple: #9879a5;
$color-gs-apple: #bad7a2;
$color-yellow: #efc16f;
$color-gray-light: #f7f7f7;
$color-gray-sand: #eeedef;
$color-dark-salmon: #EF956E;
$color-silver: #C7C7C7;
$color-dark-silver: #484F6D;

// Other colors
$color-black: #000;
$color-white: #fff;
$color-dark-gray: #333333;
$color-table-border: #C7C7C7;
$color-text-muted: #707070;
$color-danger: #FF193F;
$alert-danger-bg: #FEEAED;
$alert-danger-text: #000;
$alert-danger-border: $color-danger;
$table-color-red: #FCA3A3;
$table-color-green: #E4FFE1;
$table-color-orange: #FFC6B1;
$table-color-yellow: #F9EEA8;
$table-color-peach: #FFDDB1;

$table-color-red: #FCA3A3;

// Shadows
$header-shadow: 0 0 10px rgba($color-black, .2);
$sidebar-shadow: 3px 0 10px rgba($color-black, .2);
$card-shadow: 0 3px 6px rgba($color-black, .16);
$navigation-box-shadow: 0 3px 6px rgba($color-black, .2);
$table-shadow: $card-shadow;
$dropdown-shadow: $card-shadow;
$btn-shadow: 0 0 3px rgba($color-black, .16);

$btn-outline-primary-hover: #dbdce2;

// Fonts
$font-path: '../assets/fonts/'; // For web fonts
$font-family-base: 'Calibri', sans-serif;
$headings-font-family: 'Roboto', sans-serif;

// Prosidebar overrides
$sidebar-bg-color: $color-blue-oxford;
$sidebar-width-sm: 240px;
$sidebar-width: 300px;
$highlight-color: $color-white;
$submenu-bg-color: rgba($color-blue, .2);
$icon-bg-color: rgba($color-blue, .2);
$icon-size: 32px;
$border-color: #ddd;

// Helpmenu
$help-menu-width-sm: 350px;
$help-menu-width: 450px;

// Big calendar overrides
$out-of-range-color: #C6C6C6;
$out-of-range-bg-color: $color-white;
$calendar-border: #c8c8c8;
$cell-border: #c8c8c8;
$event-bg: $color-blue;
$event-border-radius: 10px;
$event-padding: 2px 8px;

// Big calendar variables
$calendar-header-color: #777B8F;

// Table
$data-grid-radius: 6px;

// Toast custom
:root {
    --toastify-color-success: #16a34a;
    --toastify-color-error: #dc2626;
}
