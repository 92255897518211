@import "../../styles/variables";
@import "../../styles/common-imports";

.imageWrap {
    height: 150px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    input:disabled ~ & {
        background-color: $input-disabled-bg !important;
    }
}

.fileWrap {
    background-color: $color-gray-sand;
    box-shadow: inset 0 0 0 1px $input-border-color;
    display: flex;
    align-items: center;
    border-radius: 0 4px 4px 0 !important;
    max-width: 100%;

    &.withImage {
        width: calc(100% - 116px); // 116 - width of the file selector button
    }

    input:disabled + & {
        background-color: $input-disabled-bg;
    }
}

.iconWrap {
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        display: block;
        margin: 0 auto;
    }
}

.fileName {
    max-width: 100%;
    padding-right: 10px;
}

.removeFileButton {
    border-color: $color-dark-gray;
    color: $color-danger;
    
    &:hover,
    &:focus {
        color: $color-danger;
        border-color: $color-dark-gray;
        background-color: transparent;
    }

    &:active {
        color: $color-danger;
    }
}

.withRemove {
    margin-right: -34px;
}
