@import '../../styles/variables';
@import '../../styles/common-imports';

.container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr auto auto;
    gap: 30px 30px;
    grid-template-areas:
    "box1"
    "box2"
    "blog"
    "desc";
    min-height: 100%;
}

.box1 {
    grid-area: box1;
}

.box2 {
    grid-area: box2;
}

.blog {
    grid-area: blog;
    max-height: 100%;
}

.desc {
    grid-area: desc;
}

.imgWrapper {
    height: 100%;
    overflow: hidden;
    position: relative;
}

.graphImgWrapper {
    img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

.pdfModal {
    max-width: 1000px;
}

.updateStyle {
    flex-grow: 1;
}

.graphText {
    min-height: 48px;
}

.cardText {
    font-size: 14px;
    color: $color-blue-oxford;
}

.objectWrap  {
    max-height: 30vh;
}

.imageWrap {
    height: clamp(200px, 30vh, 300px);

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center top;
    }
}

.box {
    background-color: $color-white;
    box-shadow: $card-shadow;
    transition: background-color .3s;

    &:hover {
        background-color: rgba($color-blue, .1);
    }
}

.boxGrey {
    transition: background-color .3s;

    &:hover {
        background-color: rgba($color-blue, .1);
    }
}

.borderBlue {
    border-color: $color-blue;

    &:hover {
        background-color: rgba($color-blue,.1);
    }
}
.borderDarkSalmon {
    border-color: $color-dark-salmon;

    &:hover {
        background-color: rgba($color-dark-salmon,.1);
    }
}
.borderBlack {
    border-color: $color-blue-oxford;

    &:hover {
        background-color: rgba($color-blue,.05);
    }

    &.noHover:hover {
        background-color: $color-white;
    }
}
.colorBlue {
    color: $color-blue !important;
}
.colorDarkSalmon {
    color: $color-dark-salmon !important;
}

.section {
    background-color: $color-gray-sand;
    padding: 30px;
    margin-left: -40px;
    margin-right: -40px;
}

.sectionHeader {
    font-size: 20px;
    color: $color-blue-oxford;
}
.sectionHeaderLarge {
    font-size: 24px;
    color: $color-blue-oxford;
}
.breadcrumb {
    li+li {
        padding-left: 2px !important;
        &::before {
            padding-right: 2px !important;
        }
    }

    a {
        text-decoration: none;
        font-size: 0.825rem;
        color: $color-blue;
    }
}

.viewContent {
    object {
        height: 80vh;
    }
}

@include media-breakpoint-up(lg) {
    .container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: 1fr 1fr auto;
        gap: 40px 40px;
        grid-template-areas:
        "box1 blog"
        "box2 blog"
        "desc desc";
    }
}

.blogTitle {
    font-size: 14px;
    padding-left: 0;
    padding-top: 8px;

    span {
        line-height: 1.4;
        color: $color-blue;
        cursor: pointer;
    }
}
