@import '../../styles/variables';

.pageTitle {
    background-color: $color-blue;
    height: 40px;
}

.title {
    color: $color-white;
    font-size: 24px;
    line-height: 1.333;
    font-family: $headings-font-family;
    font-weight: lighter;
}

.topNav {
    :global {
        a.nav-link {
            line-height: 1.1;
            font-size: 24px;

            &.active {
                font-weight: bold;
                color: $color-blue;
            }
        }
    }
}

.navDropdown {
    button {
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-blue !important;
        color: $color-white !important;
        border: none;
        border-radius: 0;
        font-size: 20px;
        padding-top: 5px;
        padding-bottom: 5px;

        &:hover,
        &:active {
            background-color: rgba($color-blue-oxford,.1) !important;
            color:  $color-white !important;
        }
    }

    :global button.show {
        background-color: rgba($color-blue-oxford,.1);
        color:  $color-white;
    }
}

.link {
    color: $color-blue-oxford;
    font-size: 20px;

    &:hover {
        background-color: $color-blue;
        color: $color-white;
    }
}

.iconSize18 {
    width: 18px;
    height: 18px;
}
