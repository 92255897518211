@import "../../common-imports";
@import "../../variables";
@import "../../mixins";

.modal-header {
    background-color: $color-blue-oxford;
    justify-content: center;
    position: relative;

    .btn-close {
        position: absolute;
        right: 12px;
        top: 9px;
        opacity: 1;
        color: $color-white;
    }
}

.modal-title {
    font-family: $font-family-base;
    font-size: 16px;
    line-height: 1.25;
    font-weight: bold;
    color: $color-white;
    padding-right: 1rem;
    @include text-truncate();
}

.modal-with-scroll,
.grid-with-scroll {
    max-height: calc(100vh - 200px);
    border-top-color: $color-silver !important;
    @include customScroll($color-gray-sand, $color-silver, $border-color);
}

@media print {
    .modal-with-scroll,
    .grid-with-scroll {
        overflow: visible !important;
        max-height: none;
    }
}
