@import '../../../styles/variables';

.breadcrumb {
    li+li {
        padding-left: 2px !important;

        &::before {
            padding-right: 2px !important;
        }
    }

    a {
        text-decoration: none;
        font-size: 0.825rem;
        color: $color-blue;
    }
}

.tableHeading {
    display: flex;
    background: $color-blue-oxford;
    border-radius: 0.5rem 0.5rem 0 0;
    border: none;
    color: $color-white;
    height: 30px;
    align-items: center;
    justify-content: center;
}

.analyUpdtBox {
    border: 1px solid $color-blue-oxford;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding: 12px 25px;
    height: 320px;
    overflow-y: auto;
}

.updatesItem {
    padding-bottom: 9px;
    margin-bottom: 12px;
    border-bottom: 1px solid $color-silver;

    a {
        text-decoration: none;
        color: $color-blue;
        font-size: 14px;
        line-height: 17px;
    }

    label {
        font-size: 10px;
        color: $color-blue-oxford;
        line-height: 13px;
    }
}

.tableHeader {
    width: 100%;

    table {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    th {
        background-color: $color-blue;
        color: $color-white;
        font-size: 0.875rem;
        white-space: normal;
    }

    tr {
        height: 2rem;

        &:first-child th {
            &:first-child {
                border-top-left-radius: 0;
            }

            &:last-child {
                border-top-right-radius: 0;
            }
        }
    }
}

.viewIcon {
    cursor: pointer;
}

.viewContent {
    object {
        height: 80vh;
    }
}

.itemTitle {
    font-size: 14px;
    line-height: 17px;
}
