@import '../../styles/common-imports';
@import '../../styles/variables';

.commentBox {
    --blank-height: 200px;
    --textarea-height: 94px;
    background-color: $color-white;
    border: 1px solid $border-color;
    border-radius: $input-border-radius;
    max-height: calc(100vh - var(--blank-height));

    &.open {
        max-height: calc(100vh - var(--blank-height) - var(--textarea-height));
    }
}

.commentList {
    li {
        background-color: $color-white;

        &:nth-child(even) {
            background-color: $color-gray-light;
        }

        &:first-child {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        &:last-child {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
}
