.commentHead {
    padding: 4px 4px 0 12px;
}

.commentBody {
    padding: 8px 12px;

    .commentHead + & {
        padding-top: 0;
    }
}

.deleteButton {
    position: relative;
    top: -1px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.commentList {
    overflow-wrap: anywhere;
}
