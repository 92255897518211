@import "./../../styles/variables";

.printableArea {
    padding: 0;
    margin: 0;
}

@media print {
    .printableArea * {
        print-color-adjust: exact;
    }
}

@page {
    margin: .25in;
    padding: 0;
}
