@import '../../styles/variables';

.fileName {
    color: $color-blue;
    margin-right: 6px;
}

.fileSize {
    font-size: 14px;
    opacity: .7;
    margin-left: 6px;
}

.fileProgress {
    width: 100%;
    margin-top: 2px;
}
