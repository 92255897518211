@import "variables";

// Colors
$primary: $color-blue-oxford;
$secondary: $color-blue;
$light: $color-gray-sand;

// Body
$body-color: $color-black;

// Fonts
$font-family-base: $font-family-base;
$headings-font-family: $headings-font-family;

// Accordion
$accordion-border-width: 0;
$accordion-border-radius: 0;
$accordion-inner-border-radius: 0;

$accordion-body-padding-y: .75rem;
$accordion-body-padding-x: .75rem;

$accordion-button-padding-y: .75rem;
$accordion-button-padding-x: .75rem;
$accordion-button-active-color: $color-blue;
$accordion-button-active-bg: $color-gray-light;

$accordion-icon-width: 1.125rem;
$accordion-icon-color: $color-blue;
$accordion-icon-active-color: $color-blue;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='#{$accordion-icon-color}' stroke-width='1.5'><path stroke-linecap='round' stroke-linejoin='round' d='M12 4.5v15m7.5-7.5h-15'/></svg>") !default;
$accordion-button-active-icon: $accordion-button-icon;

// Button
$btn-disabled-opacity: .5;

// Table
$table-border-color: $color-table-border;
$table-cell-padding-y: .25rem;
$table-striped-bg: $color-gray-light;
$table-hover-bg: $color-gray-sand;

$table-striped-bg-factor: .05;
$table-striped-bg: rgba($color-blue, $table-striped-bg-factor);
$table-hover-bg-factor: .1;
$table-hover-bg: rgba($color-blue, $table-hover-bg-factor);

// Card
$card-bg: $color-gray-sand;
$card-border-radius: .5rem;

// Form
$form-label-margin-bottom: .125rem;
$form-label-font-size: .875rem;
$form-label-color: $color-black;
$focus-ring-color: rgba($color-blue, .25);

$form-file-button-color: $color-white;
$form-file-button-bg: $color-blue;
$form-file-button-hover-bg: darken($color-blue, 5%);

$form-check-input-disabled-opacity: .75;
$form-check-label-disabled-opacity: 1;

// Navbar
$navbar-dark-color: $color-white;
$nav-link-color: $color-black;

// Progressbar
$progress-border-radius: 0;
$progress-height: 13px;
$progress-bg: 'transparent';
$progress-bar-bg: $color-blue;

// Utility
$enable-negative-margins: true;

// Inputs
$component-active-bg: $color-blue;
$input-btn-font-size: .875rem;
$input-font-size: 1rem;

// Modal
$modal-header-padding-y: 7px;
$modal-content-bg: $color-gray-light;
$btn-close-color: $color-white;

// Tooltip
$tooltip-bg: lighten($color-blue,50%);
$tooltip-opacity: 1;
$tooltip-color: $color-black;
$tooltip-font-size: 1rem;
$tooltip-max-width: 300px;

// Alerts
$alert-padding-y: .75rem;
$alert-padding-x: .75rem;

// Tabs
$nav-underline-gap: .125rem;
$nav-underline-border-width: .1875rem;
$nav-underline-link-active-color: $color-blue;

// Grid gutters
$spacer: 1rem;
$gutters: (
    // Default
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    // Custom
    40: $spacer * 2.5,
);
