@import "../../variables";

.alert {
    font-size: .875rem;

    .btn-close {
        transform: scale(.75);
        padding: .5rem;
    }
}

.alert-danger {
    background-color: $alert-danger-bg;
    border-color: $alert-danger-border;
    color: $alert-danger-text;
}
