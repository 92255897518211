@import './variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');


// Calibri font family
@font-face {
  font-family: 'Calibri';
  src: local('Calibri Italic'),
  local('Calibri-Italic'),
  url('#{$font-path}/Calibri-Italic.woff2') format('woff2'),
  url('#{$font-path}/Calibri-Italic.woff') format('woff'),
  url('#{$font-path}/Calibri-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri Bold Italic'),
  local('Calibri-BoldItalic'),
  url('#{$font-path}/Calibri-BoldItalic.woff2') format('woff2'),
  url('#{$font-path}/Calibri-BoldItalic.woff') format('woff'),
  url('#{$font-path}/Calibri-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri Bold'),
  local('Calibri-Bold'),
  url('#{$font-path}/Calibri-Bold.woff2') format('woff2'),
  url('#{$font-path}/Calibri-Bold.woff') format('woff'),
  url('#{$font-path}/Calibri-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'),
  url('#{$font-path}/Calibri.woff2') format('woff2'),
  url('#{$font-path}/Calibri.woff') format('woff'),
  url('#{$font-path}/Calibri.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Helvetica font family
@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local('Helvetica Neue LT Std 35 Thin'),
  local('HelveticaNeueLTStd-Th'),
  url('#{$font-path}/HelveticaNeueLTStd-Th.woff2') format('woff2'),
  url('#{$font-path}/HelveticaNeueLTStd-Th.woff') format('woff'),
  url('#{$font-path}/HelveticaNeueLTStd-Th.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local('Helvetica Neue LT Std 45 Light'),
  local('HelveticaNeueLTStd-Lt'),
  url('#{$font-path}/HelveticaNeueLTStd-Lt.woff2') format('woff2'),
  url('#{$font-path}/HelveticaNeueLTStd-Lt.woff') format('woff'),
  url('#{$font-path}/HelveticaNeueLTStd-Lt.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local('Helvetica Neue LT Std 65 Medium'),
  local('HelveticaNeueLTStd-Md'),
  url('#{$font-path}/HelveticaNeueLTStd-Md.woff2') format('woff2'),
  url('#{$font-path}/HelveticaNeueLTStd-Md.woff') format('woff'),
  url('#{$font-path}/HelveticaNeueLTStd-Md.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local('Helvetica Neue LT Std 55 Roman'),
  local('HelveticaNeueLTStd-Roman'),
  url('#{$font-path}/HelveticaNeueLTStd-Roman.woff2') format('woff2'),
  url('#{$font-path}/HelveticaNeueLTStd-Roman.woff') format('woff'),
  url('#{$font-path}/HelveticaNeueLTStd-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
