@import "../../../../styles/common-imports";
@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.commentsContainer {
    --blank-height: 340px; // 250px + 90px approx height of 2 fields above
    border-top-color: $color-silver !important;
    @include customScroll($color-gray-sand, $color-silver, $border-color);
}

.title {
    font-size: 18px;
}