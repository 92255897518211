@import './styles/variables';

// Fonts
@import './styles/fonts';

// Bootstrap customizations
@import './styles/bootstrap-custom';

// Import bootstrap
@import "~bootstrap/scss/bootstrap";

// Pro sidebar styles
@import '~react-pro-sidebar/dist/scss/styles.scss';

// Big Calendar
@import '~react-big-calendar/lib/sass/styles';

// Plugin customizations
@import "./styles/custom/react-data-table";
@import "./styles/custom/bootstrap";
@import "./styles/custom/big-calendar";

// Common Styles
@import "./styles/common";

// App styles
.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
