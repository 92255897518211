@import '../../styles/variables';
@import '../../styles/common-imports';

.commentBox {
    background-color: $color-white;
    border: 1px solid $border-color;
    border-radius: $input-border-radius;
}

.commentList {
    li {
        background-color: $color-white;

        &:nth-child(even) {
            background-color: $color-gray-light;
        }

        &:first-child {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        &:last-child {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
}
