@import "../variables";

.rbc-toolbar {
    button {
        border: none;
        text-decoration: none;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.rbc-header {
    font-size: 14px;
    font-weight: normal;
    color: $calendar-header-color;
    padding-bottom: 4px;

    + .rbc-header {
        border-left-color: transparent;
    }
}

.rbc-month-header ~ * {
    background-color: $color-white;
}

.rbc-day-bg {
    &:first-child {
        border-left: 1px solid $cell-border;
    }

    &:last-child {
        border-right: 1px solid $cell-border;
    }
}

.rbc-date-cell {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 0;
    text-align: left;

    &:not(.rbc-off-range) {
        color: $color-blue-oxford;
    }

    &.rbc-now {
        color: $color-blue;
    }
}

.rbc-month-view {
    border-color: transparent transparent $cell-border;
}

.rbc-event {
    font-size: 11px;
    line-height: 20px;

    &.rbc-event-allday {
        margin-left: 10px;
        margin-right: 10px;
        width: calc(100% - 20px);
    }
}

.rbc-button-link.rbc-show-more {
    margin-left: 10px;
}

.rbc-overlay {
    z-index: 99; // to make the calendar overlay show above the header
    max-height: 300px;
    overflow-y: auto;
}
