@import '../../styles/variables';

.clientLogo {
    width: auto;
    height: 50px;
    font-weight: bold;
    color: $color-blue;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    text-decoration: none;
}
