@import '../../styles/common-imports';
@import '../../styles/variables';


.infoCard {
    transition: background-color .3s;

    &:hover {
        background-color: rgba($color-blue, .2);
    }
}

.infoCardLink {
    text-decoration: none;
    transition: color .3s;

    &:hover {
        color: $color-blue;
    }
}

.infoCardLinkIcon {
    font-size: 1.5rem;
    color: inherit;
}

@include media-breakpoint-up(md) {

    .infoCard {
        min-height: 104px;
    }

}
