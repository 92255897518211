@import '../../styles/variables';
@import '../../styles/common-imports';

.breadcrumbWrap {
    padding-left: 20px;
    padding-right: 20px;
}

.breadcrumb {
    ol {
        margin-bottom: 0;
    }

    li {
        font-size: 14px;
        line-height: 17px;
        color: $color-blue !important;

        + li {
            padding-left: 1px !important;

            &::before {
                padding-right: 0 !important;
                color: $color-blue !important;
            }
        }
    }


    a {
        text-decoration: none;
        font-size: 14px;
        line-height: 17px;
        color: $color-blue;
    }

    svg {
        display: flex;
        position: relative;
        top: 1px;
    }
}

@include media-breakpoint-up(md) {

    .breadcrumbWrap {
        padding-left: 40px;
        padding-right: 40px;
    }

}
