@import '../../styles/variables';
@import '../../styles/common-imports';

.header {
    background-color: $color-white;
    color: $color-black;
    padding: 15px;
    box-shadow: $header-shadow;
    position: relative;
    z-index: 10;
    display: flex;

    &.login {
        min-height: 116px;
    }
}

.logoWrap {
    padding: 1px;
}

.logo {
    height: 53px;
    width: auto;
}

.controlBar {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    :global {
        .dropdown-item.active,
        .dropdown-item:active {
            svg {
                color: $white !important;
            }
        }
    }
}

.searchBox {
    width: 30%;
    min-width: 200px;
    max-width: 500px;
}

.helpBox {
    background-color: $color-gs-apple;
    color: $color-white;
    padding: 0;
    height: 38px;
    width: 38px;
    border-radius: 20px;
    font-weight: bold;
}

.helpBoxInner {
    height: 65%;
    width: 50%;
}

.userInitials {
    padding: 0;
    height: 38px;
    width: 38px;
    border-radius: 20px;
    font-weight: bold;

    &::after {
        display: none;
    }
}

.btnSupport {
    background-color: $color-gs-apple;
    width: 35px;
    height: 35px;
    transition: background-color .3s;

    &:hover {
        background-color: rgba($color-gs-apple,.6);
    }
}

.supportIcon {
    color: $color-blue-oxford;
    font-size: 24px;
    width: 40px;
    height: 30px;

    &.small {
        font-size: 21px;
    }
}

.supportTitle {
    font-size: 18px;
}

.supportLink {
    color: $color-blue;
}
