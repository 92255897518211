@import '../../../styles/variables';
@import '../../../styles/mixins';

.validationContainer {
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}

.formStyle {
    max-height: 500px;
    border-top-color: $color-silver !important;
    @include customScroll($color-gray-sand, $color-silver, $border-color);
}
