@import '../../styles/common-imports';
@import '../../styles/variables';

.contentWrap {
    max-height: calc(100vh - 225px);
}

.listBoxWrap {
    border-radius: 5px;
    background-color: $color-gray-light;
    padding: 20px 0;
}

.listBox {
    list-style-type: none;
    padding: 0 20px;

    li {
        background-color: $color-white;
        border-radius: 5px;
    }
}


.cohortItem {
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid transparent;
    transition:
        border-color .3s ease,
        background-color .3s ease,
        color .3s ease;

    &:hover {
        background-color: rgba($color-blue,.1);
        color: $color-blue;
        border-color: rgba($color-blue,.2);
    }

    &.noRecord:hover {
        background-color: transparent;
        color: inherit;
        border-color: transparent;
    }
}

:global .cohort-list-box {
    .active {
        color: $color-blue;
        border-color: rgba($color-blue,.4);
        pointer-events: none;
        user-select: none;
    }
}

