@import "variables";

// Create navigation box variants
@mixin navigationBox($color) {
    border: 1px solid $color;

    &:hover {
        background: rgba($color, .25);
    }

    svg {
        color: $color;
    }
}

@mixin customScroll($track, $thumb, $hover) {
    /* Firefox */
    scrollbar-color: $thumb $track;
    scrollbar-width: thin;

    /* Chrome, Edge and Safari */
    &::-webkit-scrollbar {
        height: 11px;
        width: 11px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 7px;
        background-color: $track;
    }

    &::-webkit-scrollbar-track:hover {
        background-color: $thumb;
    }

    &::-webkit-scrollbar-track:active {
        background-color: $thumb;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $thumb;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $hover;
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: $hover;
    }
}

@mixin tooltip(
    $fontSize: '14px',
    $bgColor: '#fff',
    $shadow: 0 0 5px rgba($color-black,.16),
    $padding: '2px 4px'
) {
    .tooltip {
        --bs-tooltip-bg: #{$bgColor};
        font-size: $fontSize;
        line-height: 1.1;

        :global {
            .tooltip-inner {
                box-shadow: $shadow;
                padding: $padding;
            }
        }
    }

}
