@import '../../styles/variables';
@import "../../styles/placeholders";

.pdfModal {
    max-width: 1000px;
}

.blogList {
    list-style: none;
    padding: 0;
    margin: 0;

    :global {
        @extend %table-styles;
    }
}

.blogCard {
    background: transparent;
    border: none;
    border-radius: 0;
}

.borderBottom {
    border-bottom: 1px solid rgba($color-black,.2);
}

.blogTitle {
    font-size: 14px;
    padding-left: 0;
    padding-top: 8px;

    span {
        line-height: 1.4;
        color: $color-blue;
        cursor: pointer;
    }
}

.blogAudience {
    font-size: 10px;
    display: flex;
    align-items: center;

    span {
        font-size: 1em;
    }
}

.autoWidth {
    width: auto;
    font-size: 10px;
}

.blogHeader {
    background: $color-blue-oxford;
    color: $color-white;
    border-radius: 5px 5px 0 0;
    font-size: 16px;
    padding: 8px;
    height: 35px;
}

.colorLightBlue {
    background-color: $color-blue-light !important;
    color: $color-white;
}
