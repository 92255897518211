@import "variables";

// Common table styles
%table-styles {
    tr {
        border-top: none;
        border-bottom: none;

        th,
        td {
            border-color: $color-white;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }

            a {
                color: $color-blue;
            }
        }

        &:first-child {
            th {
                &:first-child {
                    border-top-left-radius: $data-grid-radius;
                }

                &:last-child {
                    border-top-right-radius: $data-grid-radius;
                }
            }
        }

        &:last-child {
            td {
                &:first-child {
                    border-bottom-left-radius: $data-grid-radius;
                }

                &:last-child {
                    border-bottom-right-radius: $data-grid-radius;
                }
            }
        }
    }
}
