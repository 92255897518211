@import "../../variables";

.btn-primary {
    &:hover {
        background-color: rgba($color-blue-oxford, .75);
    }

    &:disabled {
        border-color: $color-gray-sand;
        background-color: $color-gray-sand;
        color: rgba($color-black, .6);

        @at-root fieldset & {
            border-color: $color-gray-sand;
            background-color: $color-gray-sand;
            color: rgba($color-black, .6);
        }
    }
}

.btn-secondary {
    &:hover {
        background-color: rgba($color-blue, .75);
    }

    &:disabled {
        border-color: $color-gray-sand;
        background-color: $color-gray-sand;
        color: rgba($color-black, .6);
    }
}

.btn-outline-primary {
    background-color: $color-white;

    &:hover {
        background-color: $btn-outline-primary-hover;
        color: $color-blue-oxford;
    }

    &:disabled {
        border-color: $color-gray-sand;
        background-color: rgba($color-white, .6);
        color: rgba($color-black, .6);
    }
}
