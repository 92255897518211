@import '../../styles/variables';

.customCheckbox {
  input[type="checkbox"] {
    height: 0;
    width: 0;
  }

  .cssLabel {
    cursor: pointer;
    padding-left: 25px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: -10px;
      width: 14px;
      height: 13px;
      border: 1px solid $color-dark-silver;
      background-size: 12px 11px;
      border-radius: 3px;
    }
  }

  input[type="checkbox"]:checked + .cssLabel:before {
    background-image: url('../../assets/images/remove-icon.svg');
    background-color: $color-blue-oxford;
  }
}