.modalContainer {
    max-width: 650px;
}

.colNoResponse {
    width:  95px;
}

.colDeclined {
    width: 75px;
}

.colConfirmed {
    width: 80px;
}

.colMaybeCount {
    width: 80px;
}

.colDateModified {
    width: 140px;
}
