.dataTables_wrapper {
    .rdt_TableHeader {
        background-color: $color-gray-light;
    }

    .rdt_TableHead {
        font-weight: bold;
    }

    .rdt_TableCell {
        font-size: 16px;
    }
}
