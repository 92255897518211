@import '../../styles/variables';

.pageCol {
    padding: 0 30px;
}

// table

.tableAlertHeadertxt {
    display: flex;
    border-radius: 0.5rem 0.5rem 0 0;
    border: none;
    height: 30px;
}
.tableInfo {
    font-size: 0.75rem;
    text-align: end;
    color: $color-black;
}
.breadcrumb {
    margin-bottom: 14px;
    li+li {
        padding-left: 2px !important;
        &::before {
            padding-right: 0px !important;
            color: $color-blue !important;
        }
    }

    a {
        text-decoration: none;
        font-size: 14px;
        line-height: 17px;
        color: $color-blue;
    }
    svg {
        margin-top: -4.4px
    }
}
.notiTable {
    width: 100%;
    table {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    tr {
        th {
            background-color: $color-blue;
            color:$color-white;
            font-size: 0.875rem;
        }
        font-size: 12px;
        line-height: 2rem;

        &:first-child th {
            &:first-child {
                border-top-left-radius: 0 !important;
            }
            &:last-child {
                border-top-right-radius: 0 !important;
            }
        }
    }
}
