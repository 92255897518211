@import '../../styles/variables';
@import '../../styles/common-imports';

.sidebar {
    width: 25vw;
    max-width: $sidebar-width-sm;
    box-shadow: $sidebar-shadow;
}

.logoWrap {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo {
    height: 55px;
    width: auto;
}

.mainNav {
    display: flex;
    flex-direction: column;
}


@include media-breakpoint-up(xl) {
    .sidebar {
        width: 30vw;
        max-width: $sidebar-width;
    }
}
