@import "../../../../styles/variables";

.infoBox {
    padding: 1rem 1.25rem;
    box-shadow: $navigation-box-shadow;
    border: 1px solid $color-gs-apple;
    border-radius: 8px;
    color: $color-blue-oxford;
    font-size: 14px;

    p:last-child {
        margin-bottom: 0;
    }
}
