@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/common-imports';

.pageLinkText {
    color: $color-blue-oxford;
    text-decoration: none;
    text-align: left;
    font: normal 20px/24px $headings-font-family;
}

.navigationBox {
    text-decoration: none;
    padding: 1rem 1.25rem 0.775rem 1.35rem;
    box-shadow: $navigation-box-shadow;
    border-radius: 8px;
}

.navigationDesc {
    color: $color-blue-oxford;
    font-size: 14px;
    cursor: pointer;
}

.purple {
    @include navigationBox($color-purple);
}

.yellow {
    @include navigationBox($color-yellow);
}

.darkSalmon {
    @include navigationBox($color-dark-salmon);
}

.gsApple {
    @include navigationBox($color-gs-apple);
}

.blueLight {
    @include navigationBox($color-blue-light);
}

.disabled {
    opacity: 0.5;
    border: 1px solid $color-silver;
    cursor: default;

    svg {
        color:$color-silver;
    }
}
