@import '../../../../styles/variables';

.title,
.details {
    font-size: 18px;
}

.subTitle {
    font-family: $font-family-base;
    font-size: 18px;
}

.details {
    p {
        font-family: $font-family-base;
        margin-bottom: 24px;
    }
}

.additionalDetailsSubtitle {
    font-family: $font-family-base;
    font-size: 18px;
    line-height: 21px;
    height: 41px;
}

.meetingActions {
    button {
        font-size: 16px;
    }
}

.meetingDetails {
    height: 100%;
    max-height: 40px;
    font-size: 24px;
    width: 100%;
    max-width: 70px;
}
