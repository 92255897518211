@import "../../variables";
@import "../../common-imports";

.nav-underline {
    .nav-link {
        border-bottom-color: $color-blue;
        padding: .1875rem .5rem;
        @include border-top-radius(.3125rem);

        &:hover {
            background-color: $color-gray-sand;
            border-bottom-color: $color-blue;
        }

        &.active {
            background-color: $color-blue;
            border-bottom-color: $color-blue;
            font-weight: normal;
            color: $color-white;
        }
    }
}

.tab-container {
    display: flex;
    flex-direction: column;

    .nav-underline::after {
        content: "";
        flex-grow: 1;
        box-shadow: inset 0 -3px 0 $color-silver;
    }

    .sub-nav {
        .nav-item {
            margin-right: 4px;

            &:last-child {
                margin-right: 0;
            }
        }

        .nav-link {
            background-color: $color-white;
            border: 1px solid $color-silver;
            border-radius: 4px;
            padding: 0 4px;
            font-size: 12px;

            &.active {
                background-color: $color-blue;
                color: $color-white;
            }
        }
    }

    .tab-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        > .active {
            display: flex;
            flex-direction: column;
        }
    }
}
