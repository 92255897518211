.modalContainer {
    max-width: 600px;
}

.colAttended {
    width:  72px;
}

.colCancelled {
    width:  75px;
}

.colDeclined {
    width: 68px;
}

.colNoShow {
    width: 70px;
}

.colFutureMeeting {
    width: 75px
}
