@import "../../styles/common-imports";
@import "../../styles/variables";
@import "../../styles/mixins";

.cohortSelector {
    button {
        border-color: $input-border-color;
        background-color: $color-white;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover,
        &:active {
            background-color: $color-white !important;
        }
    }

    :global {
        .btn.show {
            background-color: $color-white;
        }

        .dropdown-menu.show {
            width: 100%;
            max-height: 142px;
            overflow-y: auto;
            @include customScroll($color-gray-sand, $color-silver, $border-color);
        }

        .form-check {
            display: flex;
            align-items: center;

            label {
                margin-left: .75rem;
                position: relative;
                top: 2px;
                flex-grow: 1;
            }
        }
    }
}

.mergeIcon {
    transform: rotate(90deg);
}
