@import "../../../../styles/variables";
@import "../../../../styles/mixins";

@include tooltip(
    $shadow: 0 3px 6px rgba($color-black,.16),
    $padding: 4px 8px
);


@media (min-width: 1400px) {
    .modalContainer {
        max-width: 1280px;
    }
}
