@import '../styles/variables';

.banner {
    min-height: 500px;
    max-height: 72vh;
    overflow: hidden;
    padding-right: 0;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        width: 50px;
        top: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(90deg, rgba($color-blue, 0), $color-blue);
        z-index: 10;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: 1;
    }
}

.content {
    max-width: 500px;
}

.title {
    font-family: $headings-font-family;
    font-size: 28px;
}

.graphic {
    height: 135px;
}
