@import 'variables';

.embed-wrapper {
    > div {
        height: 100%;
    }
}

.title {
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
    color: $color-blue-oxford;
}

.sub-title,
.action-title {
    font-size: 20px;
    font-weight: normal;
}

.action-title {
    color: $color-black;
}

.sub-title {
    text-transform: uppercase;
    color: $color-blue-oxford;
}

.cursor-pointer {
    cursor: pointer;
}

.progress {
    border: 1px solid $color-blue;
}

.rotate-90 {
    transform: rotate(90deg);
}

.form-control {
    &::file-selector-button {
        padding-left: 20px;
        padding-right: 20px;
    }

    &:disabled {
        &::file-selector-button {
            background-color: $color-gray-sand;
            color: rgba($color-black, .6);
        }
    }
}

.text-muted {
    color: $color-text-muted !important;
}

.table-alt {
    table {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    th {
        background-color: $color-blue;
        color: $color-white;
        font-size: 0.875rem;
        white-space: normal;
    }

    tr {
        height: 2rem;

        &:first-child th {
            &:first-child {
                border-top-left-radius: 0;
            }

            &:last-child {
                border-top-right-radius: 0;
            }
        }
    }
}

.minH-100 {
    min-height: 100% !important;
}

.hover-highlight {
    &:hover {
        background-color: $color-white;
    }
}

// Grid Colors
.bg-red {
    background-color: $table-color-red;
}
.bg-green {
    background-color: $table-color-green;
}
.bg-yellow {
    background-color: $table-color-yellow;
}
.bg-orange {
    background-color: $table-color-orange;
}
.bg-peach {
    background-color: $table-color-peach;
}

.required {
    .form-label {
        &::after {
            content: "*";
            display: inline-block;
            color: $color-danger;
            margin-left: 2px;
        }
    }
}
